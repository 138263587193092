
nav {
    border-bottom: 2px solid #f3f3f3;
    padding: 20px;
    z-index: 999;
}

.nav-container {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.search-container {
  width: 85%;
} 
input {
    padding: 12px 20px;
    border: none;
    background: #f7f6f6;
    outline: none;
    margin-right: 20px;
    border-radius: 5px;
    position: relative;
    width: 14rem;
}
  
.nav-icons {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 2rem;
    color: var(--primary-color) !important;
}

.user-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 24px;
  background-color: var(--primary-color);
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
}

.user-icon Link, .user-icon a{
  color: var(--light-color) !important;
}

.icon-badge-container {
  position: relative;
  display: inline-block;
}

.badge.cart-count {
  position: absolute;
  top: -10px; 
  right: -16px;
  font-weight: bolder;
  color: var(--primary-color) ;
}

.link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
  
  .nav-link {
    text-decoration: none;
    color: #333;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s ease;
  }
  
  .nav-link:hover {
    background-color: #f0f0f0;
  }
  
  .cart-link {
    position: relative;
  }

  .nav-container .search-input {
    padding: 10px 5px !important;
    width: 100% !important;
  }
  

  /* Mobile and tablet */
  @media (max-width: 768px) {
    .link-container  {
      display: none;
    }
  
    .menu-container {
      justify-content: flex-end;
    }

    .hamburger-icon {
      color: var(--primary-color);
    }

    .menu-items {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      position: absolute;
      top: 8%;
      right: 1%;
      width: 200px;
      display: none;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }

    .menu-items.show {
      display: block; /* Show when .show class is applied */
      margin-top: 1.2em;
    }
    
    .menu-item {
      padding: 12px 16px;
      text-decoration: none;
      color: #333;
      font-size: 1rem;
      font-weight: 500;
      transition: background-color 0.3s ease;
      display: block;
    }
    
    .menu-item:hover {
      background-color: #f0f0f0;
    }
  }

  @media (min-width: 769px) {
    .menu-items, .hamburger-icon {
      display: none;
    }

    .nav-icons {
      margin-left: 0.2rem !important;
    }
    
}