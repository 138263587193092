.card-container {
  display: flex;
  flex-wrap: wrap;
 /* This will allow the cards to wrap to the next row */
  margin-top: 2rem;
  z-index: -2;
}
.card {
  margin: 20px;
  border: 2px solid #ededed;
  padding: 1em;
  cursor: pointer;
  max-width: 300px; /* This will prevent the card from becoming too large */
  flex: 1 1 300px;
 /* This will make each card take up about 1/3 of the row, minus 40px for the margins */
}

.card-img {
  max-width: 100%;
 /* This will make the image take up no more than the full width of its container */
  height: auto;
 /* This will maintain the aspect ratio of the image */
  object-fit: cover;
 /* This will ensure the image fills its container, without being stretched or distorted */
  margin-bottom: 1rem;
}
.card-title {
  margin-bottom: 1rem;
}
.card-reviews {
  margin-bottom: 1rem;
  display: flex;
}
.total-reviews {
  font-size: 0.9rem;
  margin-left: 10px;
}
.card-price-rating, .card-addCart-voir {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-cart {
  background-color: var(--primary-color);
  color: var(--light-color);
}
.text-add-to-cart{
  margin-left: 5px;
}
/* Add these styles to your CSS file */
.rating-star {
  color: var(--primary-color);
 /* Gold color for filled stars */
}
.rating-star-empty {
  color: #ccc;
}
/* Custom Modal Children CSS */
.close-modal-icon {
  float: right;
  font-size: 24px;
  color: var(--primary-color);
}
.modal-product {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

@media (max-width: 768px) {
  .modal-product {
    flex-direction: column;
  }

  .add-comment {
    margin-top: 10px;
  }
}
.modal-product-details {
  text-align: left;
}
.modal-price {
  margin: 8px 0;
}
.cart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.cart-quantity {
  height: 30px;
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.quantity {
  border: none;
  text-align: center;
  width: 30px;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
  border: 1px solid var(--primary-color);
  display: inline-block;
  max-width: 30px;
 /* Add this to prevent the quantity from being bigger than the buttons */
  overflow: hidden;
}
.quantity-btn {
  border: 1px solid var(--primary-color);
  width: 30px;
  background-color: var(--primary-color);
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  color: var(--light-color);
}
.add-cart.disabled, .quantity-btn.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}
button:focus {
  outline: 0;
}
.veg, .non-veg {
  margin: 10px;
}
.badge-bg {
  background-color: #572300;
  text-transform: capitalize;
}
.badge-country{
  background-color:#222f9f;
}
