.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Add a high z-index value here */
  }
  
  .modal-content {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    /* max-height: 80vh;  */
    max-width: 900px;/* Set a maximum height for the modal content */
    overflow-y: auto; /* Add a vertical scrollbar if the content exceeds the maximum height */
  }
  
  .modal-product {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal-product img {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  