.contact-container {
  max-width: 93.5rem;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
}
  
  .contact-form {
    width: 100%;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  .contact-form button {
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }
  
  .social-media-icons {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
  }
  
  .social-media-icons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0 0.5rem;
  }
  