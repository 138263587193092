.recommended-container {
    margin-left: 1em;
}

.recommended-flex {
    display: flex;
}
  
.recommended-title {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 20px;
}