.footer {
    background-color: var(--primary-color);
    color: #fff;
    padding: 20px;
    text-align: center;
    margin-top: 4rem;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .copyright {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
  
  .footer-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-links li {
    display: inline-block;
    margin-right: 10px;
  }
  
  .footer-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  