/* avatar on Comment */
.comment-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background: #ecebeb;
  padding: 20px;
  border-radius: 5px;
}

.comment  {
  display: flex;
  justify-content: left;
  border-radius: 5px;
}

.comment-content {
  margin-top:5px;
}

.comment-profile {
  float: left;
  margin-right: 1rem;
  flex-direction: column;
}
  
.comment-profile img {
  border-radius: 50%;
  width: 40px;
}
  
.comment-author, .comment-date, .comment-user {
  font-size: 0.7rem;
  color: gray
}

.comment-user {
  margin-left: 15px;
}