.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
    padding: 0 20px;
  }
  
  .error-code {
    font-size: 8rem;
    font-weight: bold;
    color: #333;
  }
  
  .error-message {
    font-size: 1.5rem;
    color: #666;
    margin: 1rem 0;
    text-align: center;
  }
  
  .not-found-image {
    max-width: 500px;
    max-height: 500px;
    margin: 2rem 0;
  }
  
  .back-home-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .back-home-button:hover {
    background-color: #555;
  }
  