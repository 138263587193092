.sidebar {

    height: 100%;
    border-right: 2px solid #e5e5e5;
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding-left: 1.5em;
  }

  .sidebar-title {
    font-size: 22px;
    font-weight: normal;
    margin-bottom: 20px;
  }
  
  .logo-container {
    margin-bottom: 4rem;
  }
  
  .logo-container h1 {
    margin-top: 1.3rem;
  }