* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

a {
  text-decoration: none;
  color: rgb(97, 97, 97);
}

li {
  list-style: none;
}


.btn, .btns {
  background: var(--primary-color);
  color: var(--light-color);
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.btns {
  padding: 10px 20px;
  margin-right: 6px;
}

.btn {
  padding: 5px 10px;
}

.btns:hover, .btn:hover {
  background-color: #f4a148;
}
.error {
  color: red;
  font-weight: 600;
}

.success {
  color: lightgreen;
  font-weight: 600;
}

.badge {
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
}

.veg {
  background-color: #05aa6d;
}

.non-veg {
  background-color: #e10038;
}

@media (max-width: 768px) {
  .btns {
    padding: 5px;
    margin-right: 3px;
  }
  
  .btn {
    padding: 2px 5px;
  }
}
