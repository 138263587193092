.add-comment abel {
    text-align: left !important;
    display: block;

    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

textarea {
    width: 100%;
    padding: 10px;
}
.add-comment button {
    float: right;
    margin-top: 10px;
}