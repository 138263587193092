.checkout-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
  }
  
  .loading,
  .empty-cart {
    margin-top: 5rem;
  }
  
  .cart {
    margin-top: 5rem;
  }
  
  .cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
   
  .table-container {
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    overflow: hidden;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid var(--primary-color)
  }
  
  th {
    background-color: var(--primary-color);
    font-weight: bold;
    color: var(--light-color)
  }
  
  .product-info {
    display: flex;
    align-items: center;
  }
  
  .product-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .remove-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #888;
  }
  
  .remove-button:hover {
    color: #666;
  }
  

  .checkIn-no-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .checkIn-no-container .card {
    border: 1px solid #ccc;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 10px;
    width: 50vw;
    max-width: 90%;
  }
  
  .checkIn-no-container .card-title {
    color: var(--light-color);
  }
  
  .nav-link {
    background: var(--light-color);
    color: var(--primary-color);
    padding: 10px;
    border-radius: 5px;
}

.btn-group button {
  margin-left: 5px;
}

.checkout-btns {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .cart-header {
    flex-direction: column;
    align-items: left;
  }
}