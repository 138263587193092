.two-column-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  #profile-picture {
    width: 20vw;
  }
  .column-left,
  .column-right {
    flex: 1;
    margin-right: 10px; /* adjust as needed */
  }
  
  .column-right {
    margin-right: 0;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  
  .field-container {
    flex: 1 0 50%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
  
  .profile-file-container {
    margin-top: 10px;
    float: right;
  }

  .profile-file-container label {
    padding: 5px 5px 5px 10px;
    border-radius: 3px;
    margin-top: 10px;
    color: white !important;
    font-size: 14px;
  }
  .custom-file-icon {
    margin-left: 5px;
    font-weight: bolder;
    font-size: 20px;
  }
  
  .field-container input,
  .field-container textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .field-container input[type="submit"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .field-container input[type="submit"]:hover {
    background-color: #0069d9;
  }

  .field-container label {
    padding-left: 10px;
  }
  
  .auth-footer-link {
    display: flex;
    justify-content: space-between;
  }

  .profle-Input {
    display: none;
  }

  .custom-file-label-file {
    background-color: var(--primary-color);
    color: var(--ligh-color)
  }