#main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
#main-layout .content {
    flex: 1;

    flex-direction: column;
    justify-content: center;
}